import Vue from 'vue'
import VueRouter from 'vue-router'
import OwnedGroupList from '../components/owned-groups-list-view.vue'
import GroupsMembershipList from '../components/groups-membership-list-view.vue'
import OwnedAppRegistrationsListView from '../components/owned-app-registrations-list-view.vue'
import AppRegistrationEditVue from '../components/app-registration-edit/app-registration-edit-view.vue'
import PageLoggedOut from '../components/PageLoggedOut/PageLoggedOut.vue'
import OwnedGroupsEditVue from '../components/owned-groups-edit/owned-groups-edit-view.vue'
import HomeView from '../components/home-view.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/ownedgroups',
    name: 'Owned Group list',
    component: OwnedGroupList
  },
  {
    path: '/groupsmembership',
    name: 'Group Membership list',
    component: GroupsMembershipList
  },
  {
    path: '/ownedappregistrations',
    name: 'owned-app-registration-list-view',
    component: OwnedAppRegistrationsListView
  },
  {
    path: '/ownedappregistrations/:id',
    name: 'app-registration-edit-view',
    component: AppRegistrationEditVue
  },
  {
    path: '/loggedout',
    name: 'LoggedOut',
    component:PageLoggedOut
  },
  {
    path: '/ownedgroups/:id',
    name: 'owned-groups-edit-view',
    component:OwnedGroupsEditVue
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
