<template>
    <v-container>
      <meta charset="utf-8" />
        <v-toolbar flat light color="white" height="100">
          <img alt=""
               src="../assets/azure/10232-icon-service-App-Registrations.svg" height="70%"
          />
          <v-toolbar-title
            id="mesGroupes"
            style="display:initial; margin-left:120px;"
          >Vous êtes propriétaire des App Registration Azure suivants</v-toolbar-title>
        </v-toolbar>
  
        <v-toolbar
            dark
            :color="search.length ? '#4DADE3' : '#008AD7'"
        >
          <v-text-field
              flat
              single-line
              solo
              @input="GetDataOnSearch"
              class="custom-label-color"
              placeholder="Rechercher une App Registration"
              hide-details
              v-model="search"
              background-color="transparent"
          >
            <v-icon slot="prepend" color="white">fas fa-search</v-icon>
          </v-text-field>
        </v-toolbar>
  
        <v-container
          fluid
          style="background-color:#F5F5F5; height:100%; padding:0;position:relative;"
        >
          <v-container v-if="isLoading">
            <v-row>
              <v-progress-circular
                style="position:absolute; left:50%; top:30%"
                :size="50"
                color="#0F87C9"
                indeterminate
              />
            </v-row>
          </v-container>
  
          <v-container v-if="noGroup && !nothing">
            <v-alert
              prominent
              text
              type="info"
              icon="fas fa-users"
            >
              <v-row align="center">
                <v-col class="text-center">
                  <div class="text-h6">
                    Aucune App Registration trouvée
                  </div>
                  <span>
                      Vous n'êtes propriétaire d'aucune Azure App Registration.
                  </span>
                </v-col>
              </v-row>
            </v-alert>
          </v-container>
  
          <v-card flat v-if="!isLoading && nothing">
            <v-card-text
              style="color:red; background-color: #F4F4F4;"
            >Aucun élément correspondant à votre recherche n'a été trouvé</v-card-text>
          </v-card>
  
          <v-container v-if="!isLoading" style="width:1063px; ">
            <v-row justify-center align-center class="tableau" v-if="!noGroup && !nothing">
              <v-col cols="1"></v-col>
              <v-col cols="3" text-align-left>
                <v-card flat>
                  <v-card-text class="px-0 leftAlign">Display name</v-card-text>
                </v-card>
              </v-col>
  
              <v-col cols="8">
                <v-card flat>
                  <v-card-text class="px-0 leftAlign">Client ID</v-card-text>
                </v-card>
              </v-col>
            </v-row>
  
            <v-row justify-center align-center >
              <v-container fluid id="scroll-target" class="scroll-y">
                <v-row v-scroll:#scroll-target="onScroll" column>
                  <v-col v-for="entry in entries" v-bind:key="entry.id" cols="12">
                    <v-card class="nav-link mb-2">
                      <v-row>
                        <v-col cols="1" text-xs-center>
                          <v-card
                            @click="goTodetail(entry)"
                            style="cursor: pointer"
                            flat
                              :color="avatarColor(entry.displayName)"
                            class="text-sm-center groupImage white--text mt-2 ml-3"
                          >{{entry.bigramme}}
                          </v-card>
                        </v-col>
  
                        <v-col cols="3">
                          <v-card flat>
                            <v-card-text
                              class="px-1 leftAlign"
                              justify-left
                              align-left
                            >{{entry.displayName}}</v-card-text>
                          </v-card>
                        </v-col>
  
                        <v-col cols="8">
                          <v-card flat @click="goTodetail(entry)" style="cursor: pointer">
                            <v-card-text class="px-1 leftAlign">{{entry.appId}}</v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
  
          </v-container>
        </v-container>
    </v-container>
  
  </template>
  
  
  <script>
  import { mapMutations } from "vuex";
  import { debounce } from "debounce";
  import "isomorphic-fetch";
  import { Client } from "@microsoft/microsoft-graph-client";
   import VueRouter from 'vue-router';
   const { isNavigationFailure, NavigationFailureType } = VueRouter;
   
   export default {
       name: "owned-app-registration-list-view",
       components: {
    },
    data: () => {
      return {
        entries: [],
        filtries: [],
        isLoading: false,
        search: "",
        userId: '',
        nothing: false,
        noGroup: false,
        response: undefined
      };
    },
    methods: {
      ...mapMutations(["showErrorSnackbar"]),
      goTodetail(entry) { 
        this.$router.push({
          name: "app-registration-edit-view",
          params: { id: entry.id }
        });
      },
      onScroll(e) {
        this.offsetTop = e.target.scrollTop;
      },
      buildAppRegList(values){
        // Ajout d'un bigramme
        values.forEach(entry => entry.bigramme = entry.displayName ? entry.displayName.substring(0, 2).toUpperCase(): '');
        return values;
      },
      GetData() {
        if(this.$msalIsAuthenticated()){
          this.isLoading = true;
  
          //Récupération d'un token et appel MS Graph pour charger la photo
          this.$msalGetAccessTokenAsync().then(accessToken => {
            const error = "error throw by the authentication handler";
  
            // Initialize the Graph client
            const client = Client.init({
                defaultVersion: "v1.0",
                debugLogging: true,
                authProvider: (done) => {
                    done(error, accessToken);
                },
            });
  
            //Chargement des objets appartenant à l'utilisateur, de type groupe
            if(this.search){
              //Chargement des groupes correspondant à la recherche
              client.api('/me/ownedObjects/microsoft.graph.application')
                  .search(`"DisplayName:${this.search}"`)
                  .header('ConsistencyLevel','eventual')
                  .get().then(response =>{
                      this.entries = this.buildAppRegList(response.value);
                    this.isLoading = false;
                    this.nothing = (this.entries.length === 0 && (this.search != ""));
                    this.noGroup = (this.entries.length === 0 && (this.search === ""));
                  }).catch(() =>{
                      this.showErrorSnackbar({
                        text: "une erreur est survenue lors de la récupération des groupes"
                      });
                  }).finally(()=> {
                    this.isLoading = false;
                  });
            }else{
                //Chargement de tous les groupes avec securityEnabled = true
                client.api('/me/ownedObjects/microsoft.graph.application')
                  .get().then(response =>{
                    this.entries = this.buildAppRegList(response.value);
                    this.isLoading = false;
                    this.nothing = this.entries.length === 0 && (this.search != "");
                    this.noGroup = this.entries.length === 0 && (this.search === "");
                }).catch(() =>{
                    this.showErrorSnackbar({
                      text: "une erreur est survenue lors de la récupération des groupes"
                    });
                }).finally(()=> {
                  this.isLoading = false;
                });
          }
        }).catch(err => {
               this.showErrorSnackbar({
                  text: "une erreur est survenue lors de la récupération du token."
                });
               console.log(err);
          }).finally(()=> {
              this.isLoading = false;
            });
        } else {
          //redirection page LogguedOut
            this.$router.push('/loggedout').catch(error => {
                if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
                    throw Error(error);
                }
            });
        }
      },
      GetDataOnSearch: debounce(function() {
         this.GetData();
       }, 700)
    },
    mounted: function() {
         this.GetData();
    }
  };
  </script>
  
  <style scoped>
   #creerUnGroupe {
     text-transform: initial;
     color: #ffffff;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     font-size: 16px;
   }
  
   .px-0 {
     font-size: 16px;
     color: #747678;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     background-color: #f5f5f5;
   }
  
   #mesGroupes {
     color: #3c3732;
     font-size: 28px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     background-color: #ffffff;
     box-shadow: none;
   }
  
   .RechercherGroupe {
     font-size: 16px;
     color: #ffffff;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   }
  
   .menu {
     background-color: white;
   }
  
   .px-1 {
     color: #3c3732;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     font-size: 18px;
   }
  
   .tableau {
     background-color: #f5f5f5;
   }
  
   .leftAlign {
     text-align: left;
   }
  
   .groupImage {
     height: 40px;
     width: 40px;
     font-size: 24px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   }
  
   >>> .custom-label-color,
   >>> .custom-label-color input {
  
   }
  
   >>> .custom-label-color .v-input__slot::before {
       border-style: none !important;
   }
  
   >>> .custom-label-color input::placeholder {
       
   }
  </style>
  