<template>
    <v-snackbar
            style="font-size: 16px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;"
            v-model="snackbar.visible"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiline"
            :color="snackbar.color"
            :label="snackbar.color"
            :value="snackbar.color"
            bottom>
        {{ snackbar.text }}
        <v-btn :color="snackbar.color === 'error' ? '#fff' : '#0F87C9'" text dark @click.native="closeSnackbar">Fermer</v-btn>
    </v-snackbar>
</template>

<script>
    import { mapMutations } from "vuex";
    export default {
        computed: {
            snackbar() {
                return this.$store.state.snackbar;
            }
        },
        methods: {
            ...mapMutations(["closeSnackbar"])
        }
    };
</script>