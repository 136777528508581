import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router';
import router from './router'
import store from './store'
import azurePlugin from './plugins/azurePlugin';
import avatarColors from './plugins/avatarColors';

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.use(azurePlugin,store);

Vue.use(avatarColors);

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
