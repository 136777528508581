import { render, staticRenderFns } from "./app-registration-edit-owners-component.vue?vue&type=template&id=c83befce&scoped=true&"
import script from "./app-registration-edit-owners-component.vue?vue&type=script&lang=js&"
export * from "./app-registration-edit-owners-component.vue?vue&type=script&lang=js&"
import style0 from "./app-registration-edit-owners-component.vue?vue&type=style&index=0&id=c83befce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c83befce",
  null
  
)

export default component.exports