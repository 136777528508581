<template>
    <v-dialog v-model="visible"
              persistent
              width="300">
        <v-card color="primary"
                dark>
            <v-card-text>
                {{text}}
                <v-progress-linear indeterminate
                                   color="white"
                                   class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                text: ''
            }
        },
        methods: {
            showDialogLoader(payload) {
                this.text = payload.text;
                this.visible = true;
            },
            closeDialogLoader() {
                this.text = '';
                this.visible = false;
            }
        }
    };
</script>