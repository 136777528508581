<template>
    <v-container>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-toolbar flat>
                    <v-btn text icon color="#0F87C9" id="goBackButton" @click=goBack()>
                        <v-icon>fas fa-arrow-left</v-icon>
                    </v-btn>
                    <v-card flat :color="avatarColor(displayName)" class="text-sm-center avatar white--text mt-1 mx-3">
                        {{displayName.substring(0, 2).toUpperCase()}}
                    </v-card>
                    <v-toolbar-title>{{displayName}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-container fluid >
                        <!-- Display Name -->
                        <v-row dense>
                            <v-col cols="4">
                                <v-subheader>Display Name</v-subheader>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                v-model="displayName"
                                readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- Object Id -->
                        <v-row dense>
                            <v-col cols="4">
                                <v-subheader>Object Id</v-subheader>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                v-model="id"
                                readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- App Id -->
                        <v-row dense>
                            <v-col cols="4">
                                <v-subheader>Application (client) Id</v-subheader>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                v-model="appId"
                                readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>
          
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-title>Propriétaires</v-card-title>
                    <AppRegistrationEditOwners :listOwners=listOwners
                        :loading=this.isLoadingOwners
                        :shouldLoadMore="this.shouldLoadMore"
                        @loadMore="loadMore"
                        v-on:addOwner="addOwner"
                        v-on:deleteOwner="deleteOwner"
                    >
                    </AppRegistrationEditOwners>
                </v-card>
            </v-col> 
        </v-row>
       

        <v-snackbar v-model="snackbar" :bottom="y === 'bottom'" :timeout="timeout">
            {{this.snackbarText}}
            <v-btn color="#0F87C9" text @click="snackbar = false">Fermer</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
 import AppRegistrationEditOwners from './app-registration-edit-owners-component.vue';
 import {mapMutations} from "vuex";
 import { Client } from "@microsoft/microsoft-graph-client";

 export default {
     data() {
         return {         
             nextMembersLink: null,
             listOwners: [],
             isLoadingOwners: false,
             shouldLoadMore: false,
             snackbar: false,
            isDisabled: true,
            y: "top",
            timeout: 3000,
            snackbarText: {},
         }
     },
     computed:{
        displayName(){
            return this.$store.state.editedAppRegistration === undefined ? "" : this.$store.state.editedAppRegistration.displayName;
        },
        id(){
            return this.$store.state.editedAppRegistration === undefined ? "" : this.$store.state.editedAppRegistration.id;
        },
        appId(){
            return this.$store.state.editedAppRegistration === undefined ? "" : this.$store.state.editedAppRegistration.appId;
        }
     },
     components: {
        AppRegistrationEditOwners,
     },
     methods: {
         ...mapMutations(["showErrorSnackbar","setEditedAppRegistration","resetEditedAppRegistration"]),
         goBack(){
            this.$router.push({ path: '/ownedappregistrations' })
            this.resetEditedAppRegistration();
         },
         loadMore() {
             this.shouldLoadMore = false;
             if (!this.isLoadingOwners && this.nextMembersLink) {
                 this.GetOwnerList();
             }
         },
         GetAppRegistration: function(id) {
            var that = this;
             if(this.$msalIsAuthenticated()){

                 //Récupération d'un token et appel MS Graph pour charger la photo
                 this.$msalGetAccessTokenAsync().then(accessToken => {
                     const error = "error throw by the authentication handler";

                     // Initialize the Graph client
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });

                     //Lire l'App Registration
                     client.api(`applications/${id}`)          
                     .get().then(response =>{
                        this.setEditedAppRegistration(response);                        
                     }).catch(error =>{
                         that.showErrorSnackbar({
                             text: "une erreur est survenue lors de la récupération d'une App Registration."
                         });
                         console.log(error);
                     }).finally(() => {
                         //that.isLoaded = false
                     });
                 }).catch(err => {
                     that.showErrorSnackbar({
                         text: "une erreur est survenue lors de la récupération du token."
                     });
                     console.log(err);
                 }).finally(() => {
                     //that.isLoaded = false
                 });

             } else {
                 //redirection page LogguedOut
                 this.$router.push('/loggedout');
             }
         },
         GetOwnerList: function(id) {
             var that = this;
             if(this.$msalIsAuthenticated()){
                 this.isLoadingOwners = true;

                 //Récupération d'un token et appel MS Graph pour charger la photo
                 this.$msalGetAccessTokenAsync().then(accessToken => {
                     const error = "error throw by the authentication handler";

                     // Initialize the Graph client
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });

                     //Lire les owners
                     client.api(`applications/${id}/owners`)
                     .get().then(response =>{
                         const listOwners = response.value;
                         that.listOwners.splice()
                         
                         listOwners.forEach(owner => {
                             const idx = that.listOwners.findIndex(existingMember => existingMember.id === owner.id);

                             if (idx === -1){
                                 that.listOwners.push({
                                     id: owner.id,
                                     displayName: owner.displayName,
                                     mail: owner.mail,
                                     userPrincipalName: owner.userPrincipalName,
                                     type: owner["@odata.type"],
                                     mailNickname: owner.mailNickname,
                                 })
                             }
                         });
                     }).catch(error =>{
                         that.showErrorSnackbar({
                             text: "une erreur est survenue lors de la récupération des membres et des propriétaires."
                         });
                         console.log(error);
                     }).finally(() => {
                         that.isLoadingOwners = false
                     });
                 }).catch(err => {
                     that.showErrorSnackbar({
                         text: "une erreur est survenue lors de la récupération du token."
                     });
                     console.log(err);
                 }).finally(() => {
                     that.isLoadingOwners = false
                 });

             } else {
                 //redirection page LogguedOut
                 this.$router.push('/loggedout');
             }
         },

         addOwner: function(owner) {
             if( !this.listOwners.map(m => m.id).includes(owner.id))
             {
                 this.listOwners.push(owner);
             }
         },
         
         deleteOwner(id) {
             this.listOwners = this.listOwners.filter(
                owner => owner.id !== id
             );
         },

     },
     mounted: function () {

        //Charger l'App Registration en fonction de l'ID demandé
        this.GetAppRegistration(this.$route.params.id);

        //Charger les Owners
        this.GetOwnerList(this.$route.params.id);
     },
 }

</script>


<style scoped>
 #infoButton {
     height: 16px;
     width: 16px;
 }
 #deleteUser {
     font-size: 16px;
     width: 150px;
     height: 30px;
     background-color: #1A8DCB;
     color: white;
 }
 .v-input__slot {
     min-height: 0 !important;
     align-items: center !important;
     height: 32px;
 }

/* #container {
  background-color: #ffffff;
} */

/* .groupTitle {
  font-size: 28px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
} */

.avatar {
  height: 40px;
  width: 40px;
  font-size: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.groupParametersBox {
  height: 350px;
  background-color: #f5f5f5;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.containerTitle {
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.inputLabel {
  font-size: 16px;
}

.textAreaInput {
  font-size: 16px;
  padding: 0 !important;
}

#updateGroup {
  font-size: 16px;
  width: 150px;
  height: 30px;
  background-color: #1a8dcb;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: initial;
}

#descriptionField {
  background-color: white;
  width: 600px;
  padding: 0 !important;
}

.actionButton {
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: initial;
}

.textarea {
  padding: 0 !important;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: white;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: white;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: white;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
</style>
