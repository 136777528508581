<template>
  <v-container>
    <meta charset="utf-8" />
      <v-toolbar flat light color="white" height="100">
        <img alt=""
             src="../assets/azure/10223-icon-service-Groups.svg" height="70%"
        />
        <v-toolbar-title
          id="mesGroupes"
          style="display:initial; margin-left:120px;"
        >Vous êtes propriétaire des groupes Azure suivants</v-toolbar-title>
      </v-toolbar>

      <v-toolbar
          dark
          :color="search.length ? '#4DADE3' : '#008AD7'"
      >
        <v-text-field
            flat
            single-line
            solo
            @input="GetDataOnSearch"
            class="custom-label-color"
            placeholder="Rechercher un groupe"
            hide-details
            v-model="search"
            background-color="transparent"
        >
          <v-icon slot="prepend" color="white">fas fa-search</v-icon>
        </v-text-field>
      </v-toolbar>

      <v-container
        fluid
        style="background-color:#F5F5F5; height:100%; padding:0;position:relative;"
      >
        <v-container v-if="isLoading">
          <v-row>
            <v-progress-circular
              style="position:absolute; left:50%; top:30%"
              :size="50"
              color="#0F87C9"
              indeterminate
            />
          </v-row>
        </v-container>

        <v-container v-if="noGroup && !nothing">
          <v-alert
            prominent
            text
            type="info"
            icon="fas fa-users"
          >
            <v-row align="center">
              <v-col class="text-center">
                <div class="text-h6">
                  Aucun groupe trouvé
                </div>
                <span>
                    Vous n'êtes propriétaire d'aucun groupe Azure AD.
                </span>
              </v-col>
            </v-row>
          </v-alert>
        </v-container>

        <v-card flat v-if="!isLoading && nothing">
          <v-card-text
            style="color:red; background-color: #F4F4F4;"
          >Aucun élément correspondant à votre recherche n'a été trouvé</v-card-text>
        </v-card>

          <!-- Liste éditable des propriétaires et membres -->
          <v-list v-if="!isLoading">
          <template v-for="(user,index) in entries">
            <v-list-item v-bind:key="user.id" @click="goTodetail(user)">
                <!-- Colonne avatar couleur avec initales -->
                <v-list-item-avatar
                    :color="avatarColor(user.displayName)"
                    class="white--text mr-8"
                    style="justify-content: center"
                    v-text="user.displayName.substring(0, 2).toUpperCase()">    
                </v-list-item-avatar>

                <!-- Colonne Display Name et description -->
                <v-list-item-content>
                    <v-list-item-title v-text="user.displayName"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-content>
                    <v-list-item-subtitle v-text="user.description"></v-list-item-subtitle>
                </v-list-item-content>
                
                <v-list-item-content>
                  <v-list-item-icon v-if="user.ADMMembersOnly">
                    <v-icon color="orange">fas fa-user-lock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle v-if="user.ADMMembersOnly">Groupe à privilège. Comptes ADM requis.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          
            <!-- Séparateur de lignes -->
            <v-divider
                v-if="index < entries.length - 1"
                :key="index"
            ></v-divider>
          
          </template>
          </v-list>
      </v-container>
  </v-container>
</template>


<script>
import { mapMutations } from "vuex";
import { debounce } from "debounce";
import "isomorphic-fetch";
import { Client } from "@microsoft/microsoft-graph-client";
import VueRouter from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = VueRouter;
import {isPrivilegedGroup} from "../plugins/eSNCFGroups";
 
 export default {
     name: "owned-groups-list-view",
     components: {
  },
  data: () => {
    return {
      entries: [],
      filtries: [],
      isLoading: false,
      search: "",
      userId: '',
      nothing: false,
      noGroup: false,
      response: undefined
    };
  },
  methods: {
    ...mapMutations(["showErrorSnackbar"]),
    goTodetail(entry) {
      this.$router.push({
          name: "owned-groups-edit-view",
          params: { id: entry.id }
        });
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    buildGroupList(values){
        //Filtrage pour ne conserver que les groupes de sécurité Azure AD
        let groups = values.filter(entry=> entry.securityEnabled === true && entry.groupTypes.length === 0);
        // Ajout d'un bigramme
        groups.forEach(entry => entry.bigramme = entry.displayName ? entry.displayName.substring(0, 2).toUpperCase(): '');

        //Ajout d'un indicateur précisant si le groupe ne doit contenir que des comptes ADM
        groups.forEach(entry => entry.ADMMembersOnly = isPrivilegedGroup(entry));
      return groups;
    },
    GetData() {
      if(this.$msalIsAuthenticated()){
        this.isLoading = true;

        //Récupération d'un token et appel MS Graph pour charger la photo
        this.$msalGetAccessTokenAsync().then(accessToken => {
          const error = "error throw by the authentication handler";

          // Initialize the Graph client
          const client = Client.init({
              defaultVersion: "v1.0",
              debugLogging: true,
              authProvider: (done) => {
                  done(error, accessToken);
              },
          });

          //Chargement des objets appartenant à l'utilisateur, de type groupe
          if(this.search){
            //Chargement des groupes correspondant à la recherche
            client.api('/me/ownedObjects/microsoft.graph.group')
                .search(`"DisplayName:${this.search}"`)
                .header('ConsistencyLevel','eventual')
                .get().then(response =>{
                    this.entries = this.buildGroupList(response.value);
                  this.isLoading = false;
                  this.nothing = (this.entries.length === 0 && (this.search != ""));
                  this.noGroup = (this.entries.length === 0 && (this.search === ""));
                }).catch(() =>{
                    this.showErrorSnackbar({
                      text: "une erreur est survenue lors de la récupération des groupes"
                    });
                }).finally(()=> {
                  this.isLoading = false;
                });
          }else{
              //Chargement de tous les groupes avec securityEnabled = true
              client.api('/me/ownedObjects/microsoft.graph.group')
                .get().then(response =>{
                  this.entries = this.buildGroupList(response.value);
                  this.isLoading = false;
                  this.nothing = this.entries.length === 0 && (this.search != "");
                  this.noGroup = this.entries.length === 0 && (this.search === "");
              }).catch(() =>{
                  this.showErrorSnackbar({
                    text: "une erreur est survenue lors de la récupération des groupes"
                  });
              }).finally(()=> {
                this.isLoading = false;
              });
        }
      }).catch(err => {
             this.showErrorSnackbar({
                text: "une erreur est survenue lors de la récupération du token."
              });
             console.log(err);
        }).finally(()=> {
            this.isLoading = false;
          });
      } else {
        //redirection page LogguedOut
          this.$router.push('/loggedout').catch(error => {
              if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
                  throw Error(error);
              }
          });
      }
    },
    GetDataOnSearch: debounce(function() {
       this.GetData();
     }, 700)
  },
  mounted: function() {
       this.GetData();
  }
};
</script>

<style scoped>
 #creerUnGroupe {
   text-transform: initial;
   color: #ffffff;
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   font-size: 16px;
 }

 .px-0 {
   font-size: 16px;
   color: #747678;
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   background-color: #f5f5f5;
 }

 #mesGroupes {
   color: #3c3732;
   font-size: 28px;
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   background-color: #ffffff;
   box-shadow: none;
 }

 .RechercherGroupe {
   font-size: 16px;
   color: #ffffff;
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
 }

 .menu {
   background-color: white;
 }

 .px-1 {
   color: #3c3732;
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   font-size: 18px;
 }

 .tableau {
   background-color: #f5f5f5;
 }

 .leftAlign {
   text-align: left;
 }

 .groupImage {
   height: 40px;
   width: 40px;
   font-size: 24px;
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
 }

 >>> .custom-label-color,
 >>> .custom-label-color input {

 }

 >>> .custom-label-color .v-input__slot::before {
     border-style: none !important;
 }

 >>> .custom-label-color input::placeholder {
     
 }
</style>
