<template>
    <v-dialog
     :value="showDialog" @click:outside="close()"
      width="500"
    >
    <v-card>
        <v-card-title class="text-h5">
            <v-icon left color="blue"> fas fa-info-circle</v-icon>
            A propos
        </v-card-title>

        <v-divider></v-divider>
        <v-img src="../assets/azure/10221-icon-service-Azure-Active-Directory.svg" height="100" contain></v-img>
        <v-card-subtitle class="text-center">
            {{ appTitle }}<br>{{ packageVersion }}
        </v-card-subtitle>
        <v-card-text class="text-center">
          Cette application web vous permet de consulter et de gérer les objets Azure AD auxquels vous êtes lié. Soit en tant que propriétaire, soit en tant que membre.
          <br><br>
          Elle utilise votre identité pour interragir avec l'api Microsoft Graph.
          <br><br>
          <v-img src="../assets/Logo ASO.svg" height="70" contain></v-img>
          <p class="font-italic">Made with ❤️ by DEA ASO Cloud - Core Team Azure</p>
        </v-card-text>
 

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close()"
          >
            Fermer
          </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'about-dialog',
        props: ["dialog","appTitle","packageVersion"],
        computed: {
            showDialog() {
                return this.dialog;
            }
        },
        methods: {
            close() {
                this.$emit('close')
            },
        }
    }
</script>