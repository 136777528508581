<template>
    <v-container>
        <v-app-bar
                   dark
                   flat
                   height="60px"
                   app
                   id="header-app-bar"
                   v-bind:class="{devel: env != 'production'}">

            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          
            <router-link to="/">
                <img src="../../assets/logo.png"
                     id="Logo"
                     class="mt-1"
                     alt="logo sncf" />
            </router-link>
            <v-toolbar-title id="appTitle">{{appTitle}}<span class="env" v-if="env != 'production'">{{ env }}</span></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <span id="userInfo" class="mx-2">Bienvenue, {{account_name}}</span>
            </v-toolbar-items>
            <v-avatar>
              <img
                  :src="photoUrl"
                  :alt="account_name"
                  v-if="photoUrl != undefined && photoUrl.length > 0"
              >
              <v-icon v-else>fa-user</v-icon>
            </v-avatar>
        </v-app-bar>

        <v-navigation-drawer 
        v-model="drawer"
        app
        absolute
        temporary
        >
        <v-list nav dense>
            <v-list-item>
                <v-list-item-avatar>
                    <v-img src="../../assets/azure/10221-icon-service-Azure-Active-Directory.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{appTitle}}</v-list-item-title>
                    <v-list-item-subtitle>{{packageVersion}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-group no-action >
                <template v-slot:activator>
                    <v-list-item-icon>
                            <v-img src="../../assets/azure/10223-icon-service-Groups.svg"></v-img>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Groupes</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                    v-for="([title,route], i) in groupMenuEntries"
                    :key="i"
                    link @click="$router.push({ path: route })"
                >
                    <v-list-item-title v-text="title"></v-list-item-title>
                </v-list-item>
        
            </v-list-group>

            <v-list-item @click="$router.push({ path: '/ownedappregistrations' })">
                    <v-list-item-icon>
                        <v-img src="../../assets/azure/10232-icon-service-App-Registrations.svg"></v-img>
                    </v-list-item-icon>
                <v-list-item-title>App Registration</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click.stop="doShowDialog = true">
                    <v-list-item-icon>
                        <v-icon color="blue"> fas fa-info-circle</v-icon>
                    </v-list-item-icon>
                <v-list-item-title>A propos</v-list-item-title>
                <AboutDialog :dialog="doShowDialog" 
                                @close="doShowDialog = false"
                                :app-title="appTitle"
                                :package-version="packageVersion"></AboutDialog>
            </v-list-item>
        </v-list>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
    import { mapMutations } from "vuex";
    import { Client } from "@microsoft/microsoft-graph-client";
    import AboutDialog from "../about-dialog.vue";

    export default {
        name: 'AppBarNavigation',
        components: { AboutDialog },
        watch: {
            '$route' (to, from) {
                console.log('Route changed from ' + from.path + ' to ' + to.path);
            }
        },
        data: () => ({
            drawer: false,
            groupMenuEntries: [
                ['Je suis propriétaire','/ownedgroups'],
                ['Je suis membre','/groupsmembership'],
            ],
            packageVersion : process.env.PACKAGE_VERSION || '-',
            appTitle: "e.SNCF Azure AD Manager",
            doShowDialog:false,
        }),
        computed:{
                account_name(){ 
                    return this.$store.state.account_name
                },
                env(){ 
                    return process.env.NODE_ENV
                },
                photoUrl(){
                     return this.$store.state.account_photo ? URL.createObjectURL(this.$store.state.account_photo) : '';
                }
        },
        methods: {
            ...mapMutations(['setAccountName','setAccountPhoto','resetAccountName']),
            loadUserData(){
                //Récupération des données du compte loggué
                if((this.account_name === '' || this.photoUrl === '') && this.$msalIsAuthenticated()){
                    //Récupération du nom du compte
                    this.setAccountName(this.$msalGetAccount().name);

                    //Récupération d'un token et appel MS Graph pour charger la photo
                     this.$msalGetAccessTokenAsync().then(accessToken => {
                        //Appel photo utilisateur

                        const error = "error throw by the authentication handler";

                        // Initialize the Graph client
                        const client = Client.init({
                            defaultVersion: "v1.0",
                            debugLogging: true,
                            authProvider: (done) => {
                                done(error, accessToken);
                            },
                        });

                        //Chargement photo
                        client.api("/me/photo/$value").get().then(photo =>{
                            this.setAccountPhoto(photo);
                        }).catch(error =>{
                            console.log(`Pas de photo à charger : ${error.message}` );
                        });
                    });
                }
            }
        },
        mounted() {
            this.loadUserData();
        }
    }; 

</script>
