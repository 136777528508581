<template>
  <v-container>
         <!-- Recherche des utilisateurs à ajouter -->
        <v-navigation-drawer
            width="604"
            v-model="addMembersDialog"
            app
            right
            temporary
        >
          <v-container fluid>
            <v-text-field
                placeholder="Ajouter ou rechercher un membre (nom ou email)"
                single-line
                hide-details
                prepend-icon="fas fa-search"
                v-model="search"
                outline
                hide-no-data
                @keyup="searchData"

            ></v-text-field>

            <v-progress-circular
                v-show="loader"
                color="secondary"
                style="position:relative; top: 30px; right: -250px"
                :size="35"
                indeterminate
            />

            <v-list
                v-if="displayResults && notFound && !loader"
                two-line
                subheader
                style="width:604px; top: 56px; right:20px; height:70px; vertical-align:middle"
            >
              <v-list-item>
                <v-list-item-content style="align-items:center" color="secondary">
                  <v-list-item-title
                      class="accent--text"
                      style="text-align:center;"
                  >Aucun élément correspondant à votre recherche n'a été trouvé</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list
                v-if="displayResults & !notFound && !loader"
                two-line
                subheader
                class="mb-2"
                style="width:604px; top: 56px; overflow: auto; max-height: 400px;"
            >
              <v-subheader v-if="subheaderUtil" class="subheader">Membre du groupe</v-subheader>
              <v-list-item
                  v-for="user in userList"
                  :key="user.id"
              >
                <v-list-item-avatar>
                  <v-avatar dark flat color="primary" class="userImage white--text">
                    <span>{{user.displayName.substring(0, 2).toUpperCase()}}</span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.user'">{{ user.mail }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.group'">Groupe</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary">fas fa-link</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>

              <v-subheader v-if="subheaderNotUtil" class="subheader">Non membre du groupe</v-subheader>
              <v-list-item
                  v-for="user in notUserList"
                  :key="user.id"
                  @click="addMember(user)"
              >
                <v-list-item-avatar>
                  <v-avatar dark flat color="primary" class="userImage white--text">
                    <span>{{user.displayName.substring(0, 2).toUpperCase()}}</span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.user'">{{user.mail}}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.group'">Groupe</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary">fas fa-plus</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-container>
        </v-navigation-drawer>

        <!-- Barre de boutons liste des propriétaires -->
        <v-toolbar flat >
            <v-checkbox
                v-show="listOwnersAndMembers.length > 0"
                v-model="allChecked"
                @change="checkAllToggle"
                color="primary"
                class="mt-2"
                :indeterminate="allChecked"
                label="Tout sélectionner"
            ></v-checkbox>
            <v-btn
                class="ml-16"
                color="primary"
                @click.stop="addMembersDialog = !addMembersDialog"
            >
            Ajouter 
            <v-icon dark right>
                fa-plus fas
            </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                v-show="listOwnersAndMembers.length > 0"
                color="secondary"
                class="ml-4"
                :disabled="checkedUsers.length === 0"
                @click="showBulkDeleteDialog"
            >Supprimer la sélection
            <v-icon dark right>
                fa-times fas
            </v-icon>
            </v-btn>
        </v-toolbar>

        <!-- Liste éditable des propriétaires -->
        <v-list>
            <template v-for="(user, index) in listOwnersAndMembers">
                    <v-list-item :key="user.id" >
                        <!-- Colonne checkbox sélection -->
                        <v-list-item-action>
                            <v-checkbox 
                                v-model="checkedUsers"
                                :value="user.id"
                                @change="checkedUsersChanged"
                            ></v-checkbox>
                        </v-list-item-action>

                        <!-- Colonne avatar couleur avec initales -->
                        <v-list-item-avatar
                            :color="avatarColor(user.displayName)"
                            class="white--text mr-8"
                            style="justify-content: center"
                            v-text="user.displayName.substring(0, 2).toUpperCase()"
                        >    
                        </v-list-item-avatar>

                        <!-- Colonne Display Name et email -->
                        <v-list-item-content>
                            <v-list-item-title v-text="user.displayName"></v-list-item-title>
                            <v-list-item-subtitle v-text="user.mail"></v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- Colonne sélection rôle -->
                        <v-list-item-action>
                            <v-select
                                :items="rolesItem"
                                dense
                                v-model="user.role"
                                @input="changeRole(user)"
                                :disabled="user.type!=='#microsoft.graph.user'"
                            ></v-select>
                        </v-list-item-action>

                        <!-- Colonne bouton suppression -->
                        <v-list-item-action>
                            <v-btn
                                text
                                fab
                                icon
                                small
                                color="secondary"
                                @click.stop="showDeleteMemberDialog(user)"
                            >
                                <v-icon>fas fa-times</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <!-- Séparateur de lignes -->
                    <v-divider
                        v-if="index < listOwnersAndMembers.length - 1"
                        :key="index"
                    ></v-divider>
            </template>
        </v-list>

        <v-container fluid >
            <v-chip label color="primary">
            {{this.countAllMembers}}
            </v-chip>
        </v-container>

        <v-card
            flat
            v-if="!this.loading && this.shouldLoadMore"
            @click="loadMoreItem"
            class="borderList"
            style="display:flex; justify-content:center;"
        >
            <span style="padding-top: 20px; color:#0f87c9;cursor: pointer;">Charger plus ...</span>
        </v-card>

        <v-card
            flat
            v-if="this.loading"
            class="borderList"
            style="display:flex; padding: 30px 0; justify-content:center; align-items:center"
        >
            <v-progress-circular
                style="color:#0F87C9; background-color: white;"
                :size="35"
                indeterminate
            />
        </v-card>

      <!-- Dialogue suppression propriétaire -->
      <v-dialog v-model="deleteMemberDialogVisible" width="660">
        <v-card>
          <v-card-text class="thetitle text-xs-center">Suppression d'un membre</v-card-text>

          <v-card-text class="thecontent text-xs-center">
            Voulez-vous vraiment supprimer ce membre du groupe ?
            <br />
            <strong>Cette action est irréversible.</strong>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                outlined
                color="#D52C1E"
                class="actionButton"
                style="margin:10px;"
                @click="deleteMemberDialogVisible = false"
            >Annuler</v-btn>

            <v-btn
                text
                id="confirmDelete"
                class="actionButton"
                style="margin:10px;"
                @click="deleteMember"
            >Supprimer le membre</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="massDialog" width="660">
        <v-card>
          <v-card-title class="thetitle text-xs-center">Suppression de plusieurs membres</v-card-title>

          <v-card-text class="thecontent text-xs-center">
            Voulez-vous vraiment supprimer ces membres du groupe ?
            <br />
            <strong>Cette action est irréversible.</strong>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                outlined
                color="primary"
                @click="massDialog = false"
            >Annuler</v-btn>

            <v-btn
                id="confirmDelete"
                color="accent"
                @click="deleteSelected"
            >Supprimer la sélection
              <v-icon right>
                fa-times
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <DialogLoader ref="dialogLoader"></DialogLoader>
  </v-container>
</template>

<script>
 import DialogLoader from "../common/DialogLoader";
 import { directive as onClickaway } from "vue-clickaway";
 import { debounce } from "debounce";
 import { mapMutations } from "vuex";
 import { Client } from "@microsoft/microsoft-graph-client";

 const ROLES = {
     Owner: "Propriétaire",
     Member: "Membre",
     OwnerMember: "Propriétaire et Membre"
 };

 export default {
     props: ["listOwnersAndMembers", "loading", "shouldLoadMore","ADMMembersOnly"],
     components: {
         DialogLoader
     },
     directives: {
         onClickaway: onClickaway
     },
     data() {
         return {
             rolesItem: [ROLES.Owner, ROLES.Member, ROLES.OwnerMember],
             rolesGroup: [ROLES.Member],
             deleteMemberDialogVisible: false,
             massDialog: false,
             search: "",
             userList: [],
             notUserList: [],
             displayResults: false,
             addMembersDialog: false,
             checkedUsers: [],
             memberToDelete: null,
             timeout: 4000,
             allChecked: false,
             loader: false
         };
     },
     computed: {
         countOwners() {
             return this.listOwnersAndMembers.filter(
                 m => m.role === ROLES.Owner || m.role === ROLES.OwnerMember
             ).length;
         },
         countAllMembers(){
             return this.listOwnersAndMembers.length;
         },
         notFound(){
            return this.notUserList.length === 0 && this.userList.length === 0;
         },
         subheaderUtil(){
            return this.userList.length > 0;
         },
         subheaderNotUtil(){
            return this.notUserList.length > 0;
         },
         id(){
            return this.$store.state.editedGroup === undefined ? "" : this.$store.state.editedGroup.id;
         }
     },
     methods: {
         ...mapMutations(["showSnackbar", "showErrorSnackbar"]),

         showDialogLoader(payload) {
             this.$refs.dialogLoader.showDialogLoader(payload);
         },
         
         closeDialogLoader() {
             this.$refs.dialogLoader.closeDialogLoader();
         },
         
         showBulkDeleteDialog() {
             const checkedOwnersCount = this.checkedUsers.reduce((acc, id) => {
                 let currentMember = this.listOwnersAndMembers.find(
                     item => item.id === id
                 );
                 if (
                     currentMember.role === ROLES.Owner ||
                     currentMember.role === ROLES.OwnerMember
                 ) {
                     return acc + 1;
                 }
                 return acc;
             }, 0);

             if (this.countOwners === checkedOwnersCount) {
                 this.showSnackbar({
                     text:
            "Vous ne pouvez pas retirer tous les propriétaires d'un groupe"
                 });
             } else {
                 this.massDialog = true;
             }
         },

         checkAllToggle() {
             if (this.checkedUsers.length === 0) {
                //Tout sélectionner
                 for (const user of this.listOwnersAndMembers) {
                    if(!user.displayName.includes('PAM_RECONCIL_')){
                        this.checkedUsers.push(user.id);
                    }            
                 }
             } else {
                //Tout désélectionner
                 this.checkedUsers.splice(0, this.checkedUsers.length);
             }
         },
        checkedUsersChanged() {
             if (this.checkedUsers.length === 0) {
                 this.allChecked = false;
             } else {
                 this.allChecked = true;
             }
         },
                  
         deleteMembers: function(members) {
             var that = this;
             if(this.$msalIsAuthenticated()){
                 return this.$msalGetAccessTokenAsync().then((accessToken) => {
                     const error = "error throw by the authentication handler";
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });
                     const promises = members.map((member) => {
                         if (member.role === ROLES.Member || member.role === ROLES.OwnerMember) {
                             return that.graphDeleteMember(client, member).then(() => {
                                 this.$emit("deleteMember", member.id);
                             });
                         }
                         if (member.role === ROLES.Owner || member.role === ROLES.OwnerMember) {
                             return that.graphDeleteOwner(client, member).then(() => {
                                 this.$emit("deleteMember", member.id);
                             });
                         }
                     });
                     return Promise.all(promises);
                 })
             } else {
                 this.$router.push('/loggedout');
                 return new Promise();
             }
         },

         deleteSelected: function() {
             const that = this;
             this.massDialog = false;
             this.showDialogLoader({ text: "Retrait en masse en cours ..." });
             const checkedMembers = this.checkedUsers.map(id =>
                 this.listOwnersAndMembers.find(member => member.id === id)
             );
             this.deleteMembers(checkedMembers).then(() => {
                 this.checkedUsers.splice(0, this.checkedUsers.length);
                 this.allChecked = false;
                 this.showSnackbar({
                     text: "Les membres ont été retirés"
                 });
             }).catch(
                 () => {
                     this.showErrorSnackbar({
                         text: "Un ou plusieurs membres n'ont pas pu être retirés" });
                 }
             ).finally(() => {
                 that.closeDialogLoader();
                 this.allChecked = false;
             });
         },

         graphDeleteMember(client, user) {
             //Retrait membre
             //DELETE https://graph.microsoft.com/v1.0/groups/02bd9fd6-8f93-4758-87c3-1fb73740a315/members/{member-id}/$ref
             return client.api(`/groups/${this.id}/members/${user.id}/$ref`).delete();
         },
         
         graphDeleteOwner(client,user){
             //Retrait propriétaire
             //DELETE /groups/{id}/owners/{id}/$ref
             return client.api(`/groups/${this.id}/owners/${user.id}/$ref`).delete();
         },
         
         loadMoreItem() {
             this.$emit("loadMore");
         },
         
         graphPostMember(client,user) {
             //Ajout membre du groupe
             //https://graph.microsoft.com/v1.0/groups/02bd9fd6-8f93-4758-87c3-1fb73740a315/members/$ref
             //{
             //     "@odata.id": "https://graph.microsoft.com/v1.0/directoryObjects/{id}"
             // }
             const directoryObject = `{\r\n    "@odata.id": "https://graph.microsoft.com/v1.0/directoryObjects/${user.id}"\r\n}`;
             this.$emit("addMember", user);

             return client.api(`/groups/${this.id}/members/$ref`).post(directoryObject);
         },
         
         graphPostOwner(client,user){
             //Ajout propriétaire du groupe
             //https://graph.microsoft.com/v1.0/groups/02bd9fd6-8f93-4758-87c3-1fb73740a315/owner/$ref
             //{
             //     "@odata.id": "https://graph.microsoft.com/v1.0/users/{id}"
             // }
             const directoryObject = `{\r\n    "@odata.id": "https://graph.microsoft.com/v1.0/users/${user.id}"\r\n}`;
             this.$emit("addMember", user);
             return client.api(`/groups/${this.id}/owners/$ref`).post(directoryObject);
         },
         
         resetOldRole(user) {
             setTimeout(() => {
                 user.role = user.roleOld;
             }, 100);
         },
         
         setUserRole(user, newRole) {
             setTimeout(() => {
                 user.roleOld = user.role;
                 user.role = newRole;
             }, 100);
         },
         
         changeRolePromiseRollback(promise, user) {
             return promise
                 .then(() => {
                     user.roleOld = user.role;
                 })
                 .catch(() => {
                     this.resetOldRole(user);
                     this.showSnackbar({
                         text: "une erreur est survenue lors du changement de rôle"
                     });
                 });
         },
         
         changeRolePromiseMultipleRollback(
             createPromise,
             deletePromise,
             user,
             newRoleIfDeleteFail
         ) {
             return createPromise
                 .then(() => {
                     deletePromise
                         .then(() => {
                             user.roleOld = user.role;
                         })
                         .catch(() => {
                             this.setUserRole(user, newRoleIfDeleteFail);
                             this.showErrorSnackbar({
                                 text: "une erreur est survenue lors du changement de rôle"
                             });
                         });
                 })
                 .catch(() => {
                     this.resetOldRole(user);
                     this.showErrorSnackbar({
                         text: "une erreur est survenue lors du changement de rôle"
                     });
                 });
         },
         
         changeRole(user) {
             if (
                 user.role === ROLES.Member &&
                 this.listOwnersAndMembers.filter(
                     member =>
                         member.role === ROLES.Owner || member.role === ROLES.OwnerMember
                 ).length === 0
             ) {
                 this.resetOldRole(user);
                 this.showSnackbar({
                     text: "Un groupe doit avoir au minimum un propriétaire"
                 });
                 return;
             }
             console.log(user);
             //Récupération des données du compte loggué
             if(this.$msalIsAuthenticated()){

                 //Récupération d'un token et appel MS Graph pour charger la photo
                 this.$msalGetAccessTokenAsync().then((accessToken) => {
                     const error = "error throw by the authentication handler";

                     // Initialize the Graph client
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });

                     var promise = Promise.resolve();
                     
                     if (user.roleOld === ROLES.Member && user.role === ROLES.Owner) {
                         promise = this.changeRolePromiseMultipleRollback(
                             this.graphPostOwner(client,user),
                             this.graphDeleteMember(client,user),
                             user,
                             ROLES.OwnerMember
                         );
                     }

                     if (user.roleOld === ROLES.Member && user.role === ROLES.OwnerMember) {
                         promise = this.changeRolePromiseRollback(this.graphPostOwner(client,user), user);
                     }

                     if (user.roleOld === ROLES.Owner && user.role === ROLES.Member) {
                         promise = this.changeRolePromiseMultipleRollback(
                             this.graphPostMember(client,user),
                             this.graphDeleteOwner(client,user),
                             user,
                             ROLES.OwnerMember
                         );
                     }

                     if (user.roleOld === ROLES.Owner && user.role === ROLES.OwnerMember) {
                         promise = this.changeRolePromiseRollback(this.graphPostMember(client,user), user);
                     }

                     if (user.roleOld === ROLES.OwnerMember && user.role === ROLES.Member) {
                         promise = this.changeRolePromiseRollback(this.graphDeleteOwner(client,user), user);
                     }

                     if (user.roleOld === ROLES.OwnerMember && user.role === ROLES.Owner) {
                         promise = this.changeRolePromiseRollback(this.graphDeleteMember(client,user), user);
                     }
                     
                     return promise.then(() => {
                         this.$emit("changeRole", user.id, user.role);
                     });
                 }).catch(err => {
                     this.showErrorSnackbar({
                         text: "une erreur est survenue lors de la récupération d'un token"
                     });
                     console.log(err);
                 });
             }
         },

         deleteMember() {
             let that = this;
             this.deleteMemberDialogVisible = false;
             this.showDialogLoader({ text: "Suppression en cours ..." });

             if (
                 this.countOwners === 1 &&
                 (this.memberToDelete.role === ROLES.Owner ||
                  this.memberToDelete.role === ROLES.OwnerMember)
             ) {
                 this.showSnackbar({
                     text:
            "Vous ne pouvez pas retirer le dernier propriétaire d'un groupe"
                 });
                 that.deleteMemberDialogVisible = false;
             } else {
                 this.deleteMembers([this.memberToDelete]).finally(() => {that.closeDialogLoader();});
             }

         },

         searchData: debounce(function() {
             console.log("searchData")
             if (this.search.length < 5 || this.search === null) {
                 this.displayResults = false;
                 return;
             }

             //Récupération des données du compte loggué
             if(this.$msalIsAuthenticated()){

                 this.$msalGetAccessTokenAsync().then(accessToken => {
                     const error = "error throw by the authentication handler";

                     // Initialize the Graph client
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });

                     //Préparation clause recherche (ADM ou non)
                     //https://graph.microsoft.com/v1.0/users?$count=true&$search="displayName:hauray"&$filter=endsWith(userPrincipalName,'_ADM@COMMUN.AD.SNCF.FR')
                     //https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(userPrincipalName,'_ADM@COMMUN.AD.SNCF.FR')

                     if(this.ADMMembersOnly){
                        //Chargement membres et groupes https://graph.microsoft.com/v1.0/users?$search="displayName:hauray"
                        client.api('/users').search(`"displayName:${this.search}" OR "userPrincipalName:${this.search}"`)
                            .filter("endsWith(userPrincipalName,'_ADM@COMMUN.AD.SNCF.FR')")
                            .header("ConsistencyLevel","eventual")
                            .get().then(responses =>{                      

                            //Ajout du type
                            responses.value.forEach(user => user.type = '#microsoft.graph.user');

                            this.searchDataBuildResult(responses.value);
                        }).catch(error =>{
                         console.log(error);
                        });
                     
                    }else{
                         //Chargement membres et groupes https://graph.microsoft.com/v1.0/users?$search="displayName:hauray"
                        Promise.all([client.api('/users')
                            .search(`"displayName:${this.search}" OR "userPrincipalName:${this.search}"`)
                            .header("ConsistencyLevel","eventual")
                            .get(),
                            client.api('/groups')
                            .search(`"displayName:${this.search}"`)
                            .header("ConsistencyLevel","eventual")
                            .get()
                        ]).then(responses =>{

                            //Ajout du type
                            responses[0].value.forEach(user => user.type = '#microsoft.graph.user');
                            responses[1].value.forEach(groupe => groupe.type = '#microsoft.graph.group');
                            
                            this.searchDataBuildResult([...responses[0].value, ...responses[1].value]);
                        }).catch(error =>{
                         console.log(error);
                        });

                    }
                 }).catch(err => {
                     this.showErrorSnackbar({
                         text: "une erreur est survenue lors de la recherche"
                     });
                     console.log(err);
                 }).finally(() => (this.loader = false));
             }else{
                 //redirection page LogguedOut
                 this.$router.push('/loggedout');
             }
         }, 700),
         searchDataBuildResult(responses){
            //Purge des tableaux
            this.notUserList.splice(0, this.notUserList.length);
            this.userList.splice(0, this.userList.length);

            //Mise à jour des listes utilisateurs owners effectifs et owner éligibles.
            responses.forEach(entry => {
                if(this.listOwnersAndMembers.findIndex(member => member.id === entry.id) != -1){
                    this.userList.push(entry);
                } else {
                    this.notUserList.push(entry);
                }
            });

            //Limitation à 5 des résultats affichés
            if (this.userList.length > 5) {
                this.userList.splice(5, this.userList.length - 5);
            }
            if (this.notUserList.length > 5) {
                this.notUserList.splice(5, this.notUserList.length - 5);
            }

            this.displayResults = true;
         },
         isOffice365(member) {
             return (
                 member.type === "Group" &&
                 member.mail &&
                 member.mail.replace(/.*@/, "") === "sncf.onmicrosoft.com"
             );
         },

         addMember(user) {
             if (this.isOffice365(user)) {
                 this.showSnackbar({
                     text: "Il est impossible d'ajouter un groupe office 365"
                 });
             } else {
                 const that = this

                 //Récupération des données du compte loggué
                 if(this.$msalIsAuthenticated()){
                     //Ajout d'un membre au groupe courant
                     this.showDialogLoader({ text: "Ajout en cours ..." });

                     //Récupération d'un token et appel MS Graph pour charger la photo
                     this.$msalGetAccessTokenAsync().then(accessToken => {
                         const error = "error throw by the authentication handler";

                         // Initialize the Graph client
                         const client = Client.init({
                             defaultVersion: "v1.0",
                             debugLogging: true,
                             authProvider: (done) => {
                                 done(error, accessToken);
                             },
                         });
                         user.role = ROLES.Member;
                         that.graphPostMember(client, user).then(() =>{
                             this.$emit("addMember", user);
                             that.showSnackbar({ text: "Le membre a bien été ajouté" });
                             //that.displaySearchResults();
                         }).catch(error =>{
                             that.showErrorSnackbar({
                                 text: "une erreur est survenue lors de l'ajout du membre"
                             });
                             console.log(error);
                         }).finally(() => that.closeDialogLoader());
                     }).catch(err => {
                         this.showErrorSnackbar({
                             text: "une erreur est survenue lors de la récupération d'un token"
                         });
                         console.log(err);
                     }).finally(() => this.closeDialogLoader());
                 } else {
                     //redirection page LogguedOut
                     this.$router.push('/loggedout');
                 }
             }
         },
         showDeleteMemberDialog(member) {
             this.deleteMemberDialogVisible = true;
             this.memberToDelete = member;
         }
     }
 };
</script>

<style scoped>
 .userImage {
     width: 40px;
     height: 40px;
     border-radius: 50%;
 }

 .actionButton {
     font-size: 16px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     text-transform: initial;
 }

 .thetitle {
     font-size: 20px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     color: #d52c1e;
 }

 .thecontent {
     font-size: 16px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     color: #929292;
 }

 .subheader {
     color: black;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     font-size: 16px;
 }

 .borderList {
     border-top: 1px solid #e1e1e0;
     border-radius: 0;
 }
</style>
