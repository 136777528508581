import {  PublicClientApplication,InteractionRequiredAuthError } from "@azure/msal-browser";

const loginRequest = {
    scopes: ["User.Read","Directory.AccessAsUser.All"]
}

const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_APP_REGISTRATION_CLIENT_ID,
        authority:'https://login.microsoftonline.com/4a7c8238-5799-4b16-9fc6-9ad8fce5a7d9',
        redirectUri: process.env.VUE_APP_REDIRECT_URI,
        postLogoutRedirectUri : process.env.VUE_APP_POST_LOGOUT_URI,
        navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  }

export default {
    
    install(Vue,store) {
        const pluginStore = store;

        //Création du client
        Vue.prototype.$msalInstance =  new PublicClientApplication(msalConfig);

        // Set active acccount on page load
        const accounts = Vue.prototype.$msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            Vue.prototype.$msalInstance.setActiveAccount(accounts[0]);
        }

        //SignIn method
        Vue.prototype.$msalSignIn = function () {
            Vue.prototype.$msalInstance.loginRedirect(loginRequest);
        }

        //Check Is authenticated
        Vue.prototype.$msalIsAuthenticated = function (){
            const accounts = Vue.prototype.$msalInstance.getAllAccounts();
            return accounts.length > 0;
        }

        // Get Active Account
        Vue.prototype.$msalGetAccount = function (){
            return Vue.prototype.$msalInstance.getActiveAccount();
        }

        //Get Access Token
        Vue.prototype.$msalGetAccessTokenAsync = function (){
            if(Vue.prototype.$msalIsAuthenticated){
                const accessTokenRequest = {
                    scopes: ["user.read","Directory.AccessAsUser.All"],
                    account: Vue.prototype.$msalInstance.getActiveAccount()
                }

                return new Promise((resolve, reject) => {
                    Vue.prototype.$msalInstance.acquireTokenSilent(accessTokenRequest).then(function(tokenResponse) {
                    // Do something with the tokenResponse
                    resolve(tokenResponse.accessToken);
                    }).catch(function(error) {
                        if (error instanceof InteractionRequiredAuthError) {
                            // fallback to interaction when silent call fails
                            Vue.prototype.$msalInstance.acquireTokenRedirect(accessTokenRequest).then(function(accessTokenResponse) {
                                // Acquire token interactive success
                                resolve(accessTokenResponse.accessToken);
                            }).catch(function(innerError) {
                                // Acquire token interactive failure
                                reject(innerError);
                            });
                        }
                    });
                });
            }
            return Promise.reject(new Error("User is not authenticated"));
        }


        // Handle the redirect flows
        Vue.prototype.$msalInstance.handleRedirectPromise()
        .then((tokenResponse) => {
            console.log("azurePlugin - Tokenresponse : " + JSON.stringify(tokenResponse));
            // Handle redirect response
            if (tokenResponse !== null) {
                //const accountId = tokenResponse.account.homeAccountId;
                // Display signed-in user content, call API, etc
                Vue.prototype.$msalInstance.setActiveAccount(tokenResponse.account);
                console.log("azurePlugin - New signin user");
                pluginStore.commit('setIsAuthenticated',true);
            } else {
                // In case multiple accounts exist, you can select
                const currentAccounts = Vue.prototype.$msalInstance.getAllAccounts();

                if (currentAccounts.length === 0) {
                    // no accounts signed-in, attempt to sign a user in
                    console.log("azurePlugin - Start Login redirect : " + JSON.stringify(loginRequest));
                    pluginStore.commit('setIsAuthenticated',false);
                    Vue.prototype.$msalInstance.loginRedirect(loginRequest);
                } else if (currentAccounts.length === 1) {
                    console.log("azurePlugin - existing active account");
                    Vue.prototype.$msalInstance.setActiveAccount(accounts[0]);
                    pluginStore.commit('setIsAuthenticated',true);
                } else {
                    // Add choose account code here
                    console.log("azurePlugin - multiple existing account. Choose first.");
                    Vue.prototype.$msalInstance.setActiveAccount(accounts[0]);
                    pluginStore.commit('setIsAuthenticated',true);
                }
            }    
        })
        .catch((error) => {
            // Handle redirect error
            console.log(error);
            pluginStore.commit('showErrorSnackbar',error);
        });


    },
};
