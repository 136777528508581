<template>
  <v-container>
        <!-- Recherche des utilisateurs à ajouter -->
        <v-navigation-drawer
            width="604"
            v-model="addMembersDialog"
            app
            right
            temporary
        >
          <v-container fluid>
            <v-text-field
                placeholder="Ajouter ou rechercher un propriétaire (nom ou email)"
                single-line
                hide-details
                prepend-icon="fas fa-search"
                v-model="search"
                outline
                hide-no-data
                @keyup="searchUsers"
            ></v-text-field>

            <v-progress-circular
                v-show="loader"
                color="secondary"
                style="position:relative; top: 30px; right: -250px"
                :size="35"
                indeterminate
            />

            <v-list
                v-if="displayResults && notFound && !loader"
                two-line
                subheader
                style="width:604px; top: 56px; right:20px; height:70px; vertical-align:middle"
            >
              <v-list-item>
                <v-list-item-content style="align-items:center" color="secondary">
                  <v-list-item-title
                      class="accent--text"
                      style="text-align:center;"
                  >Aucun élément correspondant à votre recherche n'a été trouvé</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list
                v-if="displayResults & !notFound && !loader"
                two-line
                subheader
                class="mb-2"
                style="width:604px; top: 56px; overflow: auto; max-height: 400px;"
            >
              <v-subheader v-if="subheaderUtil" class="subheader">Propriétaire de l'App Registration</v-subheader>
              <v-list-item
                  v-for="user in userList"
                  :key="user.id"
              >
                <v-list-item-avatar>
                  <v-avatar dark flat color="primary" class="userImage white--text">
                    <span>{{user.displayName.substring(0, 2).toUpperCase()}}</span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.user'">{{ user.mail }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.group'">Groupe</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary">fas fa-link</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>

              <v-subheader v-if="subheaderNotUtil" class="subheader">Non propriétaire</v-subheader>
              <v-list-item
                  v-for="user in notUserList"
                  :key="user.id"
                  @click="addOwner(user)"
              >
                <v-list-item-avatar>
                  <v-avatar dark flat color="primary" class="userImage white--text">
                    <span>{{user.displayName.substring(0, 2).toUpperCase()}}</span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.user'">{{user.mail}}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.type==='#microsoft.graph.group'">Groupe</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary">fas fa-plus</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-container>
        </v-navigation-drawer>

        <!-- Barre de boutons liste des propriétaires -->
            <v-toolbar flat >
              <v-checkbox
                  v-show="listOwners.length > 0"
                  v-model="allChecked"
                  @change="checkAllToggle"
                  color="primary"
                  class="mt-2"
                  :indeterminate="allChecked"
                  label="Tout sélectionner"
              ></v-checkbox>
              <v-btn
                  class="ml-16"
                  color="primary"
                  @click.stop="addMembersDialog = !addMembersDialog"
              >
                Ajouter 
                <v-icon dark right>
                  fa-plus fas
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  v-show="listOwners.length > 0"
                  color="secondary"
                  class="ml-4"
                  :disabled="checkedOwners.length === 0"
                  @click="showBulkDeleteDialog"
              >Supprimer la sélection
                <v-icon dark right>
                  fa-times fas
                </v-icon>
              </v-btn>
            </v-toolbar>

            <!-- Liste éditable des propriétaires -->
            <v-list>
                <template v-for="(owner, index) in listOwners">
                    <v-list-item :key="owner.id" >

                        <!-- Colonne checkbox sélection -->
                        <v-list-item-action>
                            <v-checkbox 
                                :disabled="owner.displayName.includes('PAM_RECONCIL_')"
                                v-model="checkedOwners"
                                :value="owner.id"
                                @change="checkedOwnersChanged"
                            ></v-checkbox>
                        </v-list-item-action>
                        
                        <!-- Colonne avatar couleur avec initales -->
                        <v-list-item-avatar
                            :color="avatarColor(owner.displayName)"
                            class="white--text mr-8"
                            style="justify-content: center"
                            v-text="owner.displayName.substring(0, 2).toUpperCase()">    
                        </v-list-item-avatar>

                        <!-- Colonne Display Name et email -->
                        <v-list-item-content>
                            <v-list-item-title v-text="owner.displayName"></v-list-item-title>
                            <v-list-item-subtitle v-text="owner.mail"></v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- Colonne bouton suppression -->
                        <v-list-item-action>
                            <v-btn
                                text
                                fab
                                icon
                                small
                                color="secondary"
                                @click.stop="showdeleteOwnerDialog(owner)"
                                :disabled="owner.displayName.includes('PAM_RECONCIL_')"
                            >
                                <v-icon>fas fa-times</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <!-- Séparateur de lignes -->
                    <v-divider
                        v-if="index < listOwners.length - 1"
                        :key="index"
                    ></v-divider>
                </template>                
            </v-list>

            <v-container fluid >
                <v-chip label color="primary">
                {{this.countOwners}}
                </v-chip>
            </v-container>

            <v-card
                flat
                v-if="!this.loading && this.shouldLoadMore"
                @click="loadMoreItem"
                class="borderList"
                style="display:flex; justify-content:center;"
            >
                <span style="padding-top: 20px; color:#0f87c9;cursor: pointer;">Charger plus ...</span>
            </v-card>

            <v-card
                flat
                v-if="this.loading"
                class="borderList"
                style="display:flex; padding: 30px 0; justify-content:center; align-items:center"
            >
                <v-progress-circular
                    style="color:#0F87C9; background-color: white;"
                    :size="35"
                    indeterminate
                />
            </v-card>

      <!-- Dialogue suppression propriétaire -->
      <v-dialog v-model="deleteOwnerDialogVisible" width="660">
        <v-card>
          <v-card-text class="thetitle text-xs-center">Supprimer un propriétaire</v-card-text>

          <v-card-text class="thecontent text-xs-center">
            Voulez-vous vraiment Supprimer ce propriétaire ?
            <br />
            <strong>Cette action est irréversible.</strong>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                outlined
                color="#D52C1E"
                class="actionButton"
                style="margin:10px;"
                @click="deleteOwnerDialogVisible = false"
            >Annuler</v-btn>

            <v-btn
                text
                id="confirmDelete"
                class="actionButton"
                style="margin:10px;"
                @click="deleteOwner"
            >Supprimer le propriétaire</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="bulkDeleteDialog" width="660">
        <v-card>
          <v-card-title class="thetitle text-xs-center">Suppression de plusieurs propriétaires</v-card-title>

          <v-card-text class="thecontent text-xs-center">
            Voulez-vous vraiment supprimer ces propriétaires ?
            <br />
            <strong>Cette action est irréversible.</strong>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                outlined
                color="primary"
                @click="bulkDeleteDialog = false"
            >Annuler</v-btn>

            <v-btn
                id="confirmDelete"
                color="accent"
                @click="deleteSelected"
            >Supprimer la sélection
              <v-icon right>
                fa-times
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <DialogLoader ref="dialogLoader"></DialogLoader>
  </v-container>
</template>

<script>
 import DialogLoader from "../common/DialogLoader";
 import { directive as onClickaway } from "vue-clickaway";
 import { debounce } from "debounce";
 import { mapMutations } from "vuex";
 import { Client } from "@microsoft/microsoft-graph-client";


 export default {
     props: ["listOwners", "loading", "shouldLoadMore"],
     components: {
         DialogLoader
     },
     directives: {
         onClickaway: onClickaway
     },
     data() {
         return {
             deleteOwnerDialogVisible: false,
             bulkDeleteDialog: false,
             search: "",
             userList: [],
             notUserList: [],
             displayResults: false,
             addMembersDialog: false,
             checkedOwners: [],
             ownerToDelete: null,
             timeout: 4000,
             allChecked: false,
             loader: false
         };
     },
     computed: {
         countOwners(){
             return this.listOwners ? this.listOwners.length : 0;
         },
         notFound(){
            return this.notUserList.length === 0 && this.userList.length === 0;
         },
         subheaderUtil(){
            return this.userList.length > 0;
         },
         subheaderNotUtil(){
            return this.notUserList.length > 0;
         },
         id(){
            return this.$store.state.editedAppRegistration === undefined ? "" : this.$store.state.editedAppRegistration.id;
        }

     },
     methods: {
         ...mapMutations(["showSnackbar", "showErrorSnackbar"]),

         showDialogLoader(payload) {
             this.$refs.dialogLoader.showDialogLoader(payload);
         },
         
         closeDialogLoader() {
             this.$refs.dialogLoader.closeDialogLoader();
         },
         
         showBulkDeleteDialog() {
             const checkedOwnersCount = this.checkedOwners.reduce((acc) => {
                return acc + 1;
             }, 0);

             if (this.countOwners === checkedOwnersCount) {
                 this.showSnackbar({
                     text:
            "Vous ne pouvez pas retirer tous les propriétaires d'un groupe"
                 });
             } else {
                 this.bulkDeleteDialog = true;
             }
         },
         
         checkAllToggle() {
             if (this.checkedOwners.length === 0) {
                //Tout sélectionner
                 for (const owner of this.listOwners) {
                    if(!owner.displayName.includes('PAM_RECONCIL_')){
                        this.checkedOwners.push(owner.id);
                    }            
                 }
             } else {
                //Tout désélectionner
                 this.checkedOwners.splice(0, this.checkedOwners.length);
             }
         },
         
         checkedOwnersChanged() {
             if (this.checkedOwners.length === 0) {
                 this.allChecked = false;
             } else {
                 this.allChecked = true;
             }
         },
         
         deleteOwners: function(owners) {
             var that = this;
             if(this.$msalIsAuthenticated()){
                 return this.$msalGetAccessTokenAsync().then((accessToken) => {
                     const error = "error throw by the authentication handler";
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });
                     const promises = owners.map((owner) => {
                             return that.graphDeleteOwner(client, owner).then(() => {
                                 this.$emit("deleteOwner", owner.id);
                                });
                     });
                     return Promise.all(promises);
                 })
             } else {
                 this.$router.push('/loggedout');
                 return new Promise();
             }
         },

         deleteSelected: function() {
             const that = this;
             this.bulkDeleteDialog = false;
             this.showDialogLoader({ text: "Retrait multiple en cours ..." });
             const ownersToDelete = this.checkedOwners.map(id =>
                 this.listOwners.find(member => member.id === id)
             );
             this.deleteOwners(ownersToDelete).then(() => {
                 this.checkedOwners.splice(0, this.checkedOwners.length);
                 this.allChecked = false;
                 this.showSnackbar({
                     text: "Les membres ont été retirés"
                 });
             }).catch(
                 () => {
                     this.showErrorSnackbar({
                         text: "Un ou plusieurs membres n'ont pas pu être retirés" });
                 }
             ).finally(() => {
                 that.closeDialogLoader();
                 this.allChecked = false;
             });
         },
        
         graphDeleteOwner(client,user){
             //Retrait propriétaire
             return client.api(`/applications/${this.id}/owners/${user.id}/$ref`).delete();
         },
         
         loadMoreItem() {
             this.$emit("loadMore");
         },
             
         graphPostOwner(client,user){
             //Ajout propriétaire application
             //https://learn.microsoft.com/en-us/graph/api/application-post-owners?view=graph-rest-1.0&tabs=http
             //https://graph.microsoft.com/v1.0/applications/{id}/owners/$ref
             //{
             //     "@odata.id": "https://graph.microsoft.com/v1.0/users/{id}"
             // }
             const directoryObject = `{\r\n    "@odata.id": "https://graph.microsoft.com/v1.0/users/${user.id}"\r\n}`;
             this.$emit("addOwner", user);
             return client.api(`/applications/${this.id}/owners/$ref`).post(directoryObject);
         },
                  
         deleteOwner() {
             let that = this;
             this.deleteOwnerDialogVisible = false;
             this.showDialogLoader({ text: "Suppression en cours ..." });

             if (
                 this.countOwners === 1 ) {
                 this.showSnackbar({
                     text:"Vous ne pouvez pas retirer le dernier propriétaire d'un groupe"
                 });
                 that.deleteOwnerDialogVisible = false;
             } else {
                 this.deleteOwners([this.ownerToDelete]).finally(() => {that.closeDialogLoader();});
             }

         },

         searchUsers: debounce(function() {
             console.log("searchUsers")
             if (this.search.length < 5 || this.search === null) {
                 this.displayResults = false;
                 return;
             }

             //Récupération des données du compte loggué
             if(this.$msalIsAuthenticated()){

                 this.$msalGetAccessTokenAsync().then(accessToken => {
                     const error = "error throw by the authentication handler";

                     // Initialize the Graph client
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });

                     //Chargement membres https://graph.microsoft.com/v1.0/users?$search="displayName:hauray"
                     client.api('/users').search(`"displayName:${this.search}" OR "userPrincipalName:${this.search}"`).header("ConsistencyLevel","eventual").get().then(response =>{
                         //Purge des tableaux
                         this.notUserList.splice(0, this.notUserList.length);
                         this.userList.splice(0, this.userList.length);

                         //Mise à jour des listes utilisateurs owners effectifs et owner éligibles.
                         response.value.forEach(entry => {
                            if(this.listOwners.findIndex(member => member.id === entry.id) != -1){
                                this.userList.push(entry);
                            } else {
                                this.notUserList.push(entry);
                            }
                        });

                        //Limitation à 5 des résultats affichés
                        if (this.userList.length > 5) {
                            this.userList.splice(5, this.userList.length - 5);
                        }
                        if (this.notUserList.length > 5) {
                            this.notUserList.splice(5, this.notUserList.length - 5);
                        }

                         //Actualisation affichage
                         this.displayResults = true;
                     }).catch(error =>{
                         console.log(error);
                     });
                 }).catch(err => {
                     this.showErrorSnackbar({
                         text: "une erreur est survenue lors de la recherche"
                     });
                     console.log(err);
                 }).finally(() => (this.loader = false));
             }else{
                 //redirection page LogguedOut
                 this.$router.push('/loggedout');
             }
         }, 500),
         addOwner(user) {
            const that = this

            //Récupération des données du compte loggué
            if(this.$msalIsAuthenticated()){
                //Ajout d'un membre au groupe courant
                this.showDialogLoader({ text: "Ajout en cours ..." });

                //Récupération d'un token et appel MS Graph pour charger la photo
                this.$msalGetAccessTokenAsync().then(accessToken => {
                    const error = "error throw by the authentication handler";

                    // Initialize the Graph client
                    const client = Client.init({
                        defaultVersion: "v1.0",
                        debugLogging: true,
                        authProvider: (done) => {
                            done(error, accessToken);
                        },
                    });

                    that.graphPostOwner(client, user).then(() =>{
                        this.$emit("addOwner", user);
                        that.showSnackbar({ text: "Le propriétaire a bien été ajouté" });
                    }).catch(error =>{
                        that.showErrorSnackbar({
                            text: "une erreur est survenue lors de l'ajout du propriétaire"
                        });
                        console.log(error);
                    }).finally(() => that.closeDialogLoader());
                }).catch(err => {
                    this.showErrorSnackbar({
                        text: "une erreur est survenue lors de la récupération d'un token"
                    });
                    console.log(err);
                }).finally(() => this.closeDialogLoader());
            } else {
                //redirection page LogguedOut
                this.$router.push('/loggedout');
            }
         },
         showdeleteOwnerDialog(member) {
             this.deleteOwnerDialogVisible = true;
             this.ownerToDelete = member;
         }
     }
 };
</script>

<style scoped>
 .userImage {
     width: 40px;
     height: 40px;
     border-radius: 50%;
 }

 .actionButton {
     font-size: 16px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     text-transform: initial;
 }

 .thetitle {
     font-size: 20px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     color: #d52c1e;
 }

 .thecontent {
     font-size: 16px;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     color: #929292;
 }

 .subheader {
     color: black;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     font-size: 16px;
 }

 .borderList {
     border-top: 1px solid #e1e1e0;
     border-radius: 0;
 }
</style>
