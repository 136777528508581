<template>
    <v-container>
        <!-- <v-avatar> -->
        <v-img src="../assets/azure/10221-icon-service-Azure-Active-Directory.svg" height="200" contain></v-img>
    <!-- </v-avatar> -->
    <v-alert
                prominent
                text
                type="info"
            >
                <v-row align="center">
                <v-col class="text-center">
                    <div class="text-h6">
                    Bienvenue dans e.SNCF Azure AD Manager
                    </div>
                    <span>
                        Ce site va vous permettre de gérer vos objets Azure AD.
                        <br>
                        Pour commencez, rendez-vous dans le menu 
                        <span class="fa-stack">
                            <i class="fas fa-circle fa-stack-2x"></i>
                            <i class="fas fa-bars fa-stack-1x fa-inverse"></i>
                        </span>à gauche.
                    </span>
                </v-col>
                </v-row>
    </v-alert>
    </v-container>
</template>

<script>
    export default {
        name: 'home-view'
    }
</script>