<template>
    <v-app id="app">
        <AppBarNavigation></AppBarNavigation>
        <v-main>
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>

        <GlobalSnackbar/>
    </v-app>
</template>

<script>
    import AppBarNavigation from "./components/app/app-bar-navigation.vue"
    import GlobalSnackbar from "./components/common/SnackbarStore";

    export default {
        name: 'App',
        components: { AppBarNavigation,GlobalSnackbar },
        watch: {
            '$route' (to, from) {
                console.log('Route changed from ' + from.path + ' to ' + to.path);
            }
        },
    }; 

</script>

<style>
    #navigation {
        width: 200px;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    #navigation li {
        background: #c00;
        color: #fff;
        border: 1px solid #600;
        margin-bottom: 1px;
    }

    #navigation li a {
        display: block;
        background: #c00;
        color: #fff;
        font: 1em "Trebuchet MS",Arial,sans-serif;
        line-height: 1em;
        text-align: center;
        text-decoration: none;
        padding: 4px 0;
    }

    #header-app-bar.devel {
     background-color: var(--v-secondary-darken1) !important;
    }

    #appTitle {
        color: white;
        font-size: 24px;
        font-family: 'Segoe UI Light', Tahoma, Geneva, Verdana, sans-serif;
        padding-left: 24px;
    }

    #appTitle .env {
        display: inline-block;
        margin-left: 30px;
    }
 
    #Logo {
        width: 61px;
        height: 32px;
    }

    #userInfo {
        color: white;
        font-size: 16px;
        font-family: 'Segoe UI Light', Tahoma, Geneva, Verdana, sans-serif;
        margin-top: 20px;
    }



@import '../public/IconStyleSheet.css';

    .customForm .v-input__slot {
        min-height: auto !important;
        margin: 0!important;
    }

    .customForm .v-select__selections {
        min-height: auto !important;
        display: flex !important;
        align-items: center !important;
        padding: 0!important;
    }

    .customForm .v-text-field--box .v-input__slot,
    .customForm .v-text-field--outline .v-input__slot {
        min-height: auto !important;
    }

   .customForm .v-select.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.theme--light.vinput__control {
       min-height: auto !important;
   }

   .customForm .v-text-field.v-text-field--enclosed .v-input__prepend-outer,
   .customForm .v-text-field.v-text-field--enclosed .v-input__prepend-inner,
   .customForm .v-text-field.v-text-field--enclosed .v-input__append-inner,
   .customForm .v-text-field.v-text-field--enclosed .v-input__append-outer{
       margin-top: 3px!important;
   }

   .customForm .v-text-field__details{
       padding: 0!important;
       margin : 0!important;
       display: none;
   }

    .customForm .theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
        border: 2px solid #E1E1E0;
    }

    .customForm .v-select__selections .v-select__selection--comma{
        font-size: 13px;
    }

    /* .textAreaInput .v-textarea.v-text-field--box.v-text-field--single-line .v-text-field__prefix, 
    .textAreaInput .v-textarea.v-text-field--enclosed.v-text-field--single-line .v-text-field__prefix, 
    .textAreaInput .v-textarea.v-text-field--box.v-text-field--single-line textarea, 
    .textAreaInput .v-textarea.v-text-field--enclosed.v-text-field--single-line textarea {
        margin-top: 4px!important;
    } */
</style>
