import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackbar: {
          visible:false,
          timeout:2000,
          multiline:true,
          color:'blue',
          text:''
        },
        accessToken: '',
        account_name:'',
        account_photo:undefined,
        IsAuthenticated:false,
        editedAppRegistration:undefined,
        editedGroup:undefined
    },
    mutations: {
      closeSnackbar (state) {
        state.snackbar.visible = false;
      },
      showErrorSnackbar (state,text){
          state.snackbar.visible = true;
          state.snackbar.text = text;
          state.snackbar.color = 'red';
      },
      showSnackbar (state,snackbar){
        state.snackbar.visible = true;
        state.snackbar.text = snackbar.text;
      },
      setAccountName(state,name){
        state.account_name = name;
      },
      resetAccountName(state){
        state.account_name = '';
      },
      setAccountPhoto(state,photoBlob){
        state.account_photo = photoBlob;
      },
      setIsAuthenticated(state,IsAuthenticated){
        state.IsAuthenticated = IsAuthenticated;
      },
      setEditedAppRegistration(state,appRegistrationEntry){
        state.editedAppRegistration = appRegistrationEntry;
      },
      resetEditedAppRegistration(state){
        state.editedAppRegistration = undefined;
      },
      setEditedGroup(state,groupEntry){
        state.editedGroup = groupEntry;
      },
      resetEditedGroup(state){
        state.editedGroup = undefined;
      }
    }
  })