<template>
    <v-container>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-toolbar flat>
                        <v-btn text icon color="#0F87C9" id="goBackButton" @click=goBack()>
                            <v-icon>fas fa-arrow-left</v-icon>
                        </v-btn>
                        <v-card flat :color="avatarColor(displayName)"
                            class="text-sm-center avatar white--text mt-1 mx-3"
                        >{{displayName.substring(0, 2).toUpperCase()}}
                        </v-card>
                        <v-toolbar-title>{{displayName}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-container fluid>
                        <v-row dense>
                            <v-col cols="4">
                                <v-subheader>Nom du groupe</v-subheader>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                v-model="displayName"
                                readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="4">
                                <v-subheader>Description du groupe</v-subheader>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                v-model="description"
                                readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="text-center">
                                <!-- Information groupes à privilèges et compte ADM -->
                                <v-alert
                                    density="compact"
                                    type="warning"
                                    icon="fas fa-user-lock"
                                    title="coucou"
                                    text
                                    v-model="ADMMembersOnly"
                                >
                                <div>"Ce groupe porte des privilèges élevés. Il ne doit comporter que des comptes ADM."</div>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-title>Propriétaires</v-card-title>
                    <OwnedGroupsEdit :listOwnersAndMembers=listOwnersAndMembers
                        :loading=this.isLoadingMembersAndOwners
                        :shouldLoadMore="this.shouldLoadMore"
                        :ADMMembersOnly="ADMMembersOnly"
                        @loadMore="loadMore"
                        v-on:addMember="addMember"
                        v-on:deleteMember="deleteMember">
                    </OwnedGroupsEdit>
                </v-card>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar" :bottom="y === 'bottom'" :timeout="timeout">
            {{this.snackbarText}}
            <v-btn color="#0F87C9" text @click="snackbar = false">Fermer</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
 import OwnedGroupsEdit from './owned-groups-edit-component.vue';
 import {mapMutations} from "vuex";
 import { Client } from "@microsoft/microsoft-graph-client";
 import {isPrivilegedGroup} from "../../plugins/eSNCFGroups";

 const ROLES = {
     Owner: "Propriétaire",
     Member: "Membre",
     OwnerMember: "Propriétaire et Membre"
 };

 export default {
     data() {
         return {
             nextMembersLink: null,
             listOwnersAndMembers: [],
             isLoadingMembersAndOwners: false,
             shouldLoadMore: false,
             y: "top",
             snackbar: false,
             timeout: 3000,
             snackbarText: {}
         }
     },
     computed:{
        displayName(){
            return this.$store.state.editedGroup === undefined ? "" : this.$store.state.editedGroup.displayName;
        },
        id(){
            return this.$store.state.editedGroup === undefined ? "" : this.$store.state.editedGroup.id;
        },
        description(){
            return this.$store.state.editedGroup === undefined ? "" : this.$store.state.editedGroup.description;
        },
        ADMMembersOnly(){
            return this.$store.state.editedGroup === undefined ? false : this.$store.state.editedGroup.ADMMembersOnly;
        }
     },
     components: {
        OwnedGroupsEdit
     },
     methods: {
         ...mapMutations(["showErrorSnackbar","setEditedGroup","resetEditedGroup"]),
         goBack(){
            this.$router.push({ path: '/ownedgroups' })
            this.resetEditedGroup();
         },
         loadMore() {
             this.shouldLoadMore = false;
             if (!this.isLoadingMembersAndOwners && this.nextMembersLink) {
                 this.GetMembersAndOwnersList();
             }
         },
         GetGroup: function(id) {
            var that = this;
             if(this.$msalIsAuthenticated()){

                 //Récupération d'un token et appel MS Graph pour charger la photo
                 this.$msalGetAccessTokenAsync().then(accessToken => {
                     const error = "error throw by the authentication handler";

                     // Initialize the Graph client
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });

                     //Lire l'App Registration
                     client.api(`groups/${id}`)          
                     .get().then(response =>{
                        response.ADMMembersOnly = isPrivilegedGroup(response);
                        this.setEditedGroup(response);                    
                     }).catch(error =>{
                         that.showErrorSnackbar({
                             text: "une erreur est survenue lors de la récupération d'un groupe."
                         });
                         console.log(error);
                     }).finally(() => {
                         //that.isLoaded = false
                     });
                 }).catch(err => {
                     that.showErrorSnackbar({
                         text: "une erreur est survenue lors de la récupération du token."
                     });
                     console.log(err);
                 }).finally(() => {
                     //that.isLoaded = false
                 });

             } else {
                 //redirection page LogguedOut
                 this.$router.push('/loggedout');
             }
         },
         GetMembersAndOwnersList: function(id) {
             var that = this;
             if(this.$msalIsAuthenticated()){
                 this.isLoadingMembersAndOwners = true;

                 //Récupération d'un token et appel MS Graph pour charger la photo
                 this.$msalGetAccessTokenAsync().then(accessToken => {
                     const error = "error throw by the authentication handler";

                     // Initialize the Graph client
                     const client = Client.init({
                         defaultVersion: "v1.0",
                         debugLogging: true,
                         authProvider: (done) => {
                             done(error, accessToken);
                         },
                     });

                     //Lire les membres
                     //https://graph.microsoft.com/v1.0/groups/cf19f63c-7afe-4b84-8a75-996f4022a698/members?$count=true
                     //Lire les owners
                     //https://graph.microsoft.com/v1.0/groups/cf19f63c-7afe-4b84-8a75-996f4022a698/owners?$count=true
                     Promise.all([
                         client.api(`groups/${id}/members`).get(),
                         client.api(`groups/${id}/owners`).get(),
                     ]).then(responses =>{
                         const listMembers = responses[0].value;
                         const listOwners = responses[1].value;
                         that.listOwnersAndMembers.splice()
                         listMembers.forEach(member => {
                             member.role = ROLES.Member;
                             const idx = that.listOwnersAndMembers.findIndex(existingMember => existingMember.id === member.id);

                             if (idx === -1){
                                 //Ajout du membre
                                 that.listOwnersAndMembers.push({
                                     id: member.id,
                                     displayName: member.displayName,
                                     mail: member.mail,
                                     userPrincipalName: member.userPrincipalName,
                                     type: member["@odata.type"],
                                     mailNickname: member.mailNickname,
                                     role: member.role,
                                     roleOld: member.role
                                 });
                             } else {
                                 //Mise à jour du role 
                                 if(that.listOwnersAndMembers[idx].role === ROLES.Owner) {
                                     that.listOwnersAndMembers[idx].role = ROLES.OwnerMember;
                                     that.listOwnersAndMembers[idx].roleOld = ROLES.OwnerMember;
                                 }
                             }
                         });
                         listOwners.forEach(owner => {
                             owner.role = ROLES.Owner;
                             const idx = that.listOwnersAndMembers.findIndex(existingMember => existingMember.id === owner.id);

                             if (idx === -1){
                                 that.listOwnersAndMembers.push({
                                     id: owner.id,
                                     displayName: owner.displayName,
                                     mail: owner.mail,
                                     userPrincipalName: owner.userPrincipalName,
                                     type: owner["@odata.type"],
                                     mailNickname: owner.mailNickname,
                                     role: owner.role,
                                     roleOld: owner.role
                                 })
                             } else {
                                 //Mise à jour du role 
                                 if(that.listOwnersAndMembers[idx].role === ROLES.Member) {
                                     that.listOwnersAndMembers[idx].role = ROLES.OwnerMember;
                                     that.listOwnersAndMembers[idx].roleOld = ROLES.OwnerMember;
                                 }
                             }
                         });

                         that.members = that.listOwnersAndMembers.length;
                     }).catch(error =>{
                         that.showErrorSnackbar({
                             text: "une erreur est survenue lors de la récupération des membres et des propriétaires."
                         });
                         console.log(error);
                     }).finally(() => {
                         that.isLoadingMembersAndOwners = false
                     });
                 }).catch(err => {
                     that.showErrorSnackbar({
                         text: "une erreur est survenue lors de la récupération du token."
                     });
                     console.log(err);
                 }).finally(() => {
                     that.isLoadingMembersAndOwners = false
                 });

             } else {
                 //redirection page LogguedOut
                 this.$router.push('/loggedout');
             }
         },

         addMember: function(member) {
             if( !this.listOwnersAndMembers.map(m => m.id).includes(member.id))
             {
                 this.listOwnersAndMembers.push(member);
             }
         },
         
         deleteMember(id) {
             this.listOwnersAndMembers = this.listOwnersAndMembers.filter(
                 member => member.id !== id
             );
         },

     },
     mounted: function () {

        //Charger le groupe en fonction de l'id demandé via la navigation
        this.GetGroup(this.$route.params.id);

        //Charger les members et les Owners
        this.GetMembersAndOwnersList(this.$route.params.id);
     },
 }

</script>


<style scoped>
 #infoButton {
     height: 16px;
     width: 16px;
 }
 #deleteUser {
     font-size: 16px;
     width: 150px;
     height: 30px;
     background-color: #1A8DCB;
     color: white;
 }
 .v-input__slot {
     min-height: 0 !important;
     align-items: center !important;
     height: 32px;
 }

 .avatar {
  height: 40px;
  width: 40px;
  font-size: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
