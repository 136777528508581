<template>
        <v-main>
            <v-alert prominent type="warning">
                <v-row align="center">
                    <v-col class="grow">
                        Vous n'êtes pas authentifié.
                    </v-col>
                </v-row>
            </v-alert>
        </v-main>
</template>

<script>

    export default {
        components: {
        },
        computed:{
            IsAuthenticated(){
                return this.$store.state.IsAuthenticated;
            }
        },
        watch: {
            IsAuthenticated(newAuthStatus){
                if(newAuthStatus){
                    this.$router.push('/');
                }
            }
        }
    }
</script>
